<template>
  <div
    :class="['Backdrop', dark && 'Backdrop--dark', show && 'is-active']"
    :style="cssVars"
    @click.self.stop="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Backdrop',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Number,
      default: 0.5,
    },
  },
  computed: {
    cssVars() {
      return {
        '--bg-opacity': this.opacity,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.Backdrop {
  z-index: 9998;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 200ms;
  pointer-events: none;

  &.Backdrop--dark {
    background-color: rgba(0, 0, 0, 0);
  }
}
.Backdrop.is-active {
  background-color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  pointer-events: all;
  &.Backdrop--dark {
    background-color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }
}
</style>
