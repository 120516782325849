<template>
  <InstantSearch
    :enable-routing="false"
    :contexts="['location_sitebar']"
    :enabled="isActivated"
    :analytics-tags="['location_sitebar']"
  >
    <PreventBodyScroll :prevent="searchActive">
      <SearchBox
        v-slot="{
          query,
          nbHits,
          refine,
        }"
        ref="searchbox"
        :delay="200"
      >
        <div class="Input">
          <div class="Input__fieldWrap">
            <i class="Input__icon far fa-search"></i>

            <input
              :value="query"
              :placeholder="$t('fireworks.search-placeholder')"
              type="search"
              name="search"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="none"
              spellcheck="false"
              role="combobox"
              class="Input Input--search Input--searchButton Input--medium Input--grey Input--tight"
              @input="refine($event.currentTarget.value)"
              @focus="onInputFocus"
            />
          </div>
        </div>

        <Hits v-slot="{ hits }">
          <div class="results" v-if="searchActive">
            <!--<div class="CloseSearch">
              <button v-on:click="deactivateSearch"><i class="far fa-times"></i></button>
            </div>-->

            <div class="Grid Grid--large">
              <ProductCardFireworks
                v-for="hit in hits"
                :key="hit.objectID"
                class="Grid__item"
                :product="hit"
              >
              </ProductCardFireworks>
            </div>
          </div>
        </Hits>
        <Backdrop :show="searchActive" :dark="true" :opacity="0.65" @click="deactivateSearch" />
        <p v-if="query && nbHits === 0">Ingen resultat</p>
      </SearchBox>
    </PreventBodyScroll>
  </InstantSearch>
</template>

<script>
import { InstantSearch, SearchBox, Hits } from '@components/Search';
import PreventBodyScroll from '@components/PreventBodyScroll';
import ProductCardFireworks from '@scenes/FireworksScene/components/ProductCardFireworks';
import Backdrop from '@components/Backdrop';

export default {
  name: 'TopbarSearch',
  components: {
    SearchBox,
    InstantSearch,
    Hits,
    ProductCardFireworks,
    PreventBodyScroll,
    Backdrop,
  },
  data() {
    return {
      searchActive: false, // Is the user currently using the search
      isActivated: false, // Has the user focused/activated the search at some point
    };
  },
  watch: {
    searchActive(to, from) {
      if (!this.isActivated && to) {
        this.activateSearch();
      }
    },
  },
  methods: {
    onInputFocus() {
      this.searchActive = true; // The search field has been focused = SHOW overlay + results
    },
    activateSearch() {
      this.isActivated = true;
      //this.$refs.searchbox.refine(); // Triggers the first fetch of search results (empty query)
    },
    deactivateSearch() {
      this.searchActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.results {
  background: #000;
  overflow: hidden;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.04);
  position: fixed;
  min-width: 470px;
  max-width: 1200px;
  z-index: 9999;
  margin: 0;
  top: 100px;
  padding: 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);

  @include maxBreakpoint(600) {
    max-width: 100%;
    min-width: 100%;
    top: auto;
    padding: 10px;
  }
}
.CloseSearch {
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 7px 10px;
  border-radius: 50%;

  button {
    border: none;
    background: none;
    font-size: 2em;
  }
}

.Input__fieldWrap {
  position: relative;
  z-index: 9999;

  .Input__icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    font-size: 22px;
  }
  input {
    background-color: #fff;
  }
}
</style>
